<template>
  <section>
    <v-card
      class="upload-info d-flex flex-column justify-space-around pa-0 ma-0 card-2 card-combo rounded-0"
    >
      <v-card-text class="d-flex justify-center">
        <v-row>
          <v-col cols="12" class="ma-0 pa-1">
            <template>
              <v-expansion-panels multiple>
                <v-divider></v-divider>
                <v-spacer></v-spacer>
                <v-expansion-panel
                  v-if="errors !== []"
                  v-for="(error, i) in errors"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    {{ `${error.colName} - ${error.errDesc}` }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          label="Row Number"
                          :value="error.rowNum"
                          type="text"
                          outlined
                          disabled
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="6">
                        <v-text-field
                          label="Row Number"
                          :value="error.valueWithError"
                          type="text"
                          outlined
                          disabled
                          hide-details
                        ></v-text-field>
                      </v-col> -->
                      <v-col cols="6">
                        <v-text-field
                          label="Column name"
                          :value="error.colName"
                          type="text"
                          outlined
                          dense
                          disabled
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Error Response"
                          :value="error.errResp"
                          type="text"
                          outlined
                          dense
                          disabled
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Error Description"
                          :value="error.errDesc"
                          type="text"
                          outlined
                          dense
                          disabled
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  props: ["info"],

  watch: {
    info: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal);

      this.errors = newVal ? newVal : [];
      // this.message = this.info.message ? this.info.message : "";
    },
  },

  created() {
    console.log("Upload step 2 created ");

    // this.errors = this.info.data.errors ? this.info.data.errors : [];
    // this.message = this.info.message ? this.info.message : "";
  },

  methods: {
    goToUpload() {
      // this.$router.push("/inventory/upload");

      this.$emit("goToStep", 1);
    },
  },

  data() {
    return {
      errors: [],
      message: "",
    };
  },
};
</script>

<style></style>
