import { exportTemplate } from "@/utils/ExportExcel.js";

const download_repots = (data, header, reportName) => {
  if (reportName === "Report_Sale_Pending")
    for (let key in data) {
      let obj = data[key];
      obj.Notes = obj.Notes.replace(
        /<img\s+src="data:image\/[^;]+;base64,[^"]+"\s*\/?>/g,
        ""
      );
    }

  exportTemplate(data, header, reportName, header.length);
};

export { download_repots };
