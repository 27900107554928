<template>
  <v-form v-model="valid" class="d-flex flex-column align-center pa-5">
    <v-row class="row row-w ma-0" justify="center">
      <v-col cols="12" md="6" class="ma-0 pa-0">
        <v-text-field v-model="userInfo.name" solo dense>
          <template v-slot:prepend>
            <v-icon class="icon-form" color="primary">mdi-account</v-icon>
          </template>
        </v-text-field>
        <v-text-field v-model="userInfo.email" solo dense>
          <template v-slot:prepend>
            <v-icon class="icon-form" color="primary">mdi-email</v-icon>
          </template>
        </v-text-field>
        <v-text-field v-model="userInfo.phone" solo dense>
          <template v-slot:prepend>
            <v-icon class="icon-form" color="primary">mdi-phone</v-icon>
          </template>
        </v-text-field>
        <v-text-field
          v-model="userInfo.org"
          solo
          dense
          disabled
          class="userInfo-input"
        >
          <template v-slot:prepend>
            <v-icon class="icon-form" color="primary"
              >mdi-card-account-details</v-icon
            >
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="row row-w ma-0">
      <v-col cols="2" md="2" class="ma-auto pa-0 d-flex">
        <v-btn block color="primary" elevation="4">Save</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    this.userInfo.name = this.user.name;
    this.userInfo.email = this.user.email;
    this.userInfo.org = this.user.organization_descr;
  },
  data: () => ({
    valid: false,
    userInfo: {
      name: "",
      org: "",
      email: "",
      phone: "",
    },
    nameRules: [
      v => !!v || "Name is required",
      v => v.length <= 25 || "Name must be less than 25 characters",
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style>
.v-input__prepend-outer {
  margin: 0;
  padding: 0;
}
.userInfo-input.theme--dark.v-input--is-disabled input {
  color: white;
}
.v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer,
.v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer {
  margin: 0;
}
</style>
<style scoped>
.row-w {
  width: 100%;
}

.icon-form {
  border: 1px solid #3284f4;
  border-radius: 50%;
  padding: 15%;
  margin-right: 20px;
  margin-top: 0;
}
</style>
