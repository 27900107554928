<template>
  <div class="header text-center profile ma-0 pa-0">
    <div class="file-wrapper">
      <v-icon
        size="15vh"
        class="dark-blue"
        :style="[opacityHover ? { opacity: 0.3 } : { opacity: 1 }]"
        >mdi-account-circle</v-icon
      >
      <div
        class="span-wrapper"
        :style="[opacityHover ? { opacity: 1 } : { opacity: 0 }]"
      >
        <v-icon size="xx-large" color="primary">mdi-account-plus</v-icon>
        <span>Change Profile Picture</span>
      </div>
      <input type="file" @mouseover="over" @mouseout="out" />
    </div>
    <h2>{{ user.name }}</h2>
  </div>
</template>

<script>
export default {
  props: ["user"],

  data() {
    return {
      opacityHover: false,
    };
  },
  methods: {
    out() {
      this.opacityHover = false;
    },
    over() {
      this.opacityHover = true;
    },
  },
};
</script>

<style scoped>
.profile {
  position: relative;
}

.profile input {
  position: absolute;
  cursor: pointer;
  top: -25%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60%;
  height: 60%;
  opacity: 0;
  margin: auto;
}

.profile .span-wrapper {
  position: absolute;
  cursor: pointer;
  top: -25%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #3284f4;
  font-size: 0.9em;
}

.file-wrapper {
  width: 80%;
  margin: auto;
}
</style>
