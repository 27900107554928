<template>
  <v-stepper-content :step="step" class="pa-0">
    <v-card class="fill-height d-flex flex-column">
      <v-row align="center" class="ma-0 pa-0 flex-grow-1 row-border">
        <v-col :cols="step == 3 ? 12 : 5" class="text-center uploadFile">
          <!-- left part -->
          <slot name="left"> </slot>
        </v-col>
        <!-- <v-col v-else class="text-center uploadFile d-flex flex-column">
          <v-icon size="10vw">mdi-file-cloud</v-icon>
          <v-btn
            type="button"
            class="btn btn-primary removeFile"
            color="error"
            @click="removeFile"
            small
            >Remove File</v-btn
          >
        </v-col> -->
        <v-col cols="7" class="pa-0 fill-height">
          <!-- right part -->
          <!-- <upload-info
            :file="file"
            :userName="user.name"
            v-on:c2pMessageTypeId="onChangeMessageTypeChild"
          ></upload-info> -->
          <slot name="right"> </slot>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="ma-2 mt-1 pa-0 justify-center flex-grow-1 flex-shrink-0"
      >
        <slot name="down"> </slot>
        <!-- down part -->
        <!-- <v-col>
          <v-btn
            block
            elevation="2"
            class="ma-0 dark-blue"
            @click="handleValidateUpload"
            >Validate</v-btn
          >
        </v-col>
        <v-col>
          <v-btn block elevation="2" class="ma-0 dark-blue">Reset</v-btn>
        </v-col> -->
      </v-row>
    </v-card>
  </v-stepper-content>
</template>

<script>
export default {
  props: ["step"],
};
</script>

<style scoped>
.uploadFile {
  height: 30%;
  position: relative;
}

.uploadFile:hover {
  border: 1px solid #2e94c4;
}

.uploadFile input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
