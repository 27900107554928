<template>
  <v-row class="pa-0 elevation-5">
    <v-col class="col-ajust" v-for="{ title, index } in tabValues" :key="title">
      <v-btn
        small
        class="px-5 py-2 ma-0 primary--text rounded-0 text-lg-caption font-weight-bold"
        :class="index === tab ? 'primary dark_blue--text bold-text' : ''"
        dense
        block
        style="border-left: thin solid #3284f4; border-right: thin solid #3284f4"
        :disabled="loading"
        @click="$emit('changeTab', index)"
      >
        <span class="text-capitalize">
          {{ title }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["tab", "loading"],
  data() {
    return {
      tabValues: [
        {
          title: "Non Gate Buy",
          index: 0,
        },

        {
          title: "Gate Buy",
          index: 1,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
