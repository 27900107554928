<template>
  <v-card
    class="upload-info d-flex flex-column justify-space-around pa-0 ma-0 card-2 card-combo rounded-0"
  >
    <v-card-text class="d-flex justify-center">
      <v-row>
        <v-col cols="12" class="ma-0 pa-4">
          <div class="text--secondary" style="text-align: left">
            Choose Mapping
          </div>

          <v-select
            v-model="messageType"
            :items="messageTypeItems.map((x) => x.name)"
            @change="onChangeMessageType"
            outlined
            dense
            solo
          ></v-select>
        </v-col>
        <v-col cols="12" class="ma-0 pa-4">
          <v-text-field
            label="File Name"
            type="text"
            v-model="name"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" class="ma-0 pa-1">
          <v-text-field
            label="Status Description"
            type="text"
            v-model="description"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </v-col> -->
        <v-col cols="6" class="ma-0 pa-4">
          <v-text-field
            label="Created by"
            type="text"
            v-model="userCreated"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="ma-0 pa-4">
          <v-text-field
            label="Date created"
            type="text"
            v-model="dateCreated"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="ma-0 pa-4">
          <div class="text--secondary" style="display: flex; flex-flow: column">
            <v-checkbox
              value
              label="Keep existing last modified date"
              @change="keepExistingLastModHandler"
            ></v-checkbox>
          </div>
        </v-col>
        <!-- <v-col cols="12" class="ma-0 pa-4">
          <div class="text--secondary" style="text-align: left;">
            Default Buyer Party for ALLOCATE
          </div>

          <v-select
            v-model="buyerParty"
            :items="buyerPartyItems ? buyerPartyItems.map(x => x.name) : []"
            @change="onChangeBuyerParties"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col> -->
        <!-- <v-col cols="6" class="ma-0 pa-1">
          <v-text-field
            label="Total rows"
            type="text"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </v-col> -->
        <!-- <v-col cols="6" class="ma-0 pa-1">
          <v-text-field
            label="Total columns"
            type="text"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </v-col> -->
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import {
  get_visible_entities,
  get_translation_maps,
} from "@/helpers/api/indexv2.js";

import stringSimilarity from "string-similarity";

export default {
  props: ["loading", "file", "userName"],
  watch: {
    file() {
      if (this.file != "") {
        this.name = this.file.name;
        this.dateCreated = DateTime.fromISO(new Date().toISOString()).toFormat(
          "LL/dd/yyyy",
        );

        this.userCreated = this.userName;
        this.messageType = this.messageType.toUpperCase();

        if (this.name.toLowerCase().includes("vendor-inventory-template")) {
          this.messageTypeId = 3;
          this.$emit("c2pMessageTypeId", 3);
          return;
        }

        const matchesFriendlyNames = stringSimilarity.findBestMatch(
          this.name.toLowerCase(),
          [
            ...this.messageTypeItems.map((x) =>
              x.mapFriendlyName.toLowerCase(),
            ),
          ],
        );

        const getMapNameFromFriendlyName = this.messageTypeItems
          .filter(
            (x) =>
              x.mapFriendlyName.toUpperCase() ==
              matchesFriendlyNames.bestMatch.target.toUpperCase(),
          )
          .map((x) => x.mapName)[0];

        console.log("getMapNameFromFriendlyName", getMapNameFromFriendlyName);

        this.messageType = matchesFriendlyNames.bestMatch.target.toUpperCase();

        this.messageTypeId = 0;
        this.$emit("c2pMessageTypeId", 0);
        this.$emit("c2pFileUploadMapName", getMapNameFromFriendlyName);
        return;
      } else {
        this.clearData();
      }
    },
  },
  data() {
    return {
      messageTypeItems: [
        {
          name: "STANDARD TEMPLATE",
          id: 3,
        },
      ],
      buyerParty: "",
      buyerPartyItems: {},
      buyerPartyId: 1,
      messageType: "STANDARD TEMPLATE",
      messageTypeId: 3,
      name: "",
      description: "",
      dateCreated: "",
      userCreated: "",
      DateTime,
      keepExistingLastMod: false,
    };
  },
  mounted() {
    this.init();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getSetTranslationMapsSelectors();

      this.messageType = "STANDARD TEMPLATE";
      this.messageTypeId = 3;

      // this.initBuyerParties();
    },

    async getSetTranslationMapsSelectors() {
      const get_translation_maps_res = await get_translation_maps();

      if (!get_translation_maps_res.pass) {
        return false;
      }

      console.log(
        "get_translation_maps_res data",
        get_translation_maps_res.data,
      );

      const enriched = get_translation_maps_res.data
        .filter((x) => x.enabled == "Y")
        .map((x) => ({
          ...x,
          name: x.mapFriendlyName.toUpperCase(),
        }));

      this.messageTypeItemsInput = [...enriched];
      this.messageTypeItems = [...enriched];

      console.log(
        "getSetTranslationMapsSelectors this.messageTypeItemsInput",
        this.messageTypeItemsInput,
      );
    },

    async initBuyerParties() {
      try {
        const { data } = await get_visible_entities();

        console.log(data);

        this.buyerPartyItems = data;
        this.buyerParty = data[0].name;
      } catch (error) {}
    },
    onChangeBuyerParties(event) {
      console.log("event", event);

      for (const buyerPartyItem of this.buyerPartyItems) {
        if (buyerPartyItem.name == event) {
          this.buyerPartyId = buyerPartyItem.id;
        }
      }

      console.log("buyerPartyId", this.buyerPartyId);
      this.$emit("c2pBuyerPartyId", this.buyerPartyId);
    },

    onChangeMessageType(event) {
      console.log("onChangeMessageType event", event);

      this.messageTypeId = this.messageTypeItems
        .filter((x) => x.name == event)
        .map((x) => x.id)[0];

      console.log(
        "this.messageTypeId",
        this.messageTypeItems
          .filter((x) => x.name == event)
          .map((x) => x.id)[0],
      );

      this.$emit("c2pMessageTypeId", this.messageTypeId);
    },

    clearData() {
      this.name = "";
      this.messageType = "";
      this.description = "";
      this.dateCreated = "";
      this.userCreated = "";
    },
    submit() {
      this.$emit("login", this.credentials);
    },
    keepExistingLastModHandler(v) {
      console.log("v", v);
      this.keepExistingLastMod = v;

      this.$emit("c2pKeepExistingLastMod", this.keepExistingLastMod);
    },
  },
};
</script>

<style scoped>
.upload-info {
  height: inherit;
}
</style>
