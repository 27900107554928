<template>
  <modal width="90%" :show="show" :title="title">
    <template v-slot>
      <v-row>
        <v-col cols="auto">
          <Tabs
            :tab="tab"
            :tabValues="[
              { title: 'containers Added' },
              { title: 'containers Not Found' },
            ]"
            @changeTab="changeTab"
            :classes="`elevation-0  font-weight-bold text-uppercase`"
            :btnclasses="'primary  lighten-2 rounded-t-lg rounded-b-0 font-weight-light white--text font-weight-bold '"
            :classActive="'primary darken-1 white--text'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-5">
          <v-data-table
            dense
            expand-icon="mdi-file-tree"
            hide-default-footer
            class="elevation-5 rounded-0 pa-0 ma-0 table-readOnly"
            height="50vh"
            :items-per-page="-1"
            :headers="header"
            :items="itemsValue"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 pa-0" justify="space-around">
        <v-col class="ma-1 pa-0" cols="12">
          <v-btn
            class="elevation-5"
            color="primary"
            style="border: none"
            text
            light
            @click="$emit('closeModal')"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions } from "vuex";
import Tabs from "@/components/General/Tabs.vue";
const HEADER = [
  {
    text: "* Name",
    value: "* Name",
  },
  {
    text: "Amount",
    value: "Amount",
  },
  {
    text: "Business Email Address",
    value: "Business Email Address",
  },
  {
    text: "City",
    value: "City",
  },
  {
    text: "Container Number",
    value: "Container Number",
  },
  {
    text: "Monthly",
    value: "Monthly",
  },
  {
    text: "Organization Name",
    value: "Organization Name",
  },
  {
    text: "Partner Code",
    value: "Partner Code",
  },
  {
    text: "Postal Code",
    value: "Postal Code",
  },
  {
    text: "Rebill number",
    value: "Rebill number",
  },
  {
    text: "Receipt Number",
    value: "Receipt Number",
  },
  {
    text: "Received $ date",
    value: "Received $ date",
  },
  {
    text: "Size",
    value: "Size",
  },
  {
    text: "State/Province/Region",
    value: "State/Province/Region",
  },
  {
    text: "Street",
    value: "Street",
  },
  {
    text: "Total amount of receipt",
    value: "Total amount of receipt",
  },
];

export default {
  components: {
    Modal,
    Tabs,
  },

  props: ["show", "title", "items"],

  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    header() {
      return generateHeaderData(HEADER);
    },
    itemsValue() {
      if (this.tab === 0) return this.items["containersAdded"];
      else return this.items["containersNotFound"];
    },
  },
  watch: {},
  methods: {
    changeTab(value) {
      this.tab = value;
    },
  },
};
</script>
