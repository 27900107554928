<template>
  <v-data-table
    class="elevation-5 main-table2 rounded-1 pa-0 ma-0"
    dense
    fixed-header
    :headers="headers"
    :items="itemsFiltered"
    height="70vh"
    width="100%"
    :no-data-text="errorMessage ? errorMessage : 'No data available'"
    :items-per-page="10000"
    :footer-props="footerProps"
    :loading="loading"
  >
  </v-data-table>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import { generateHeaderData } from "@/utils/tables.js";
import { search } from "@/utils/panelAction.js";
import { download_repots } from "@/components/Reports/utils";

export default {
  props: ["tab", "parentTabValue", "footerProps"],
  computed: {
    ...mapState({
      items: (state) => state.moduleReports.items,
      errorMessage: (state) => state.moduleReports.errorMessage,
      searchValue: (state) => state.moduleReports.searchValue,
      loading: (state) => state.moduleReports.loading,
      dates: (state) => state.moduleReports.dates,
      salRef: (state) => state.moduleReports.salRef,
      refreshTable: (state) => state.moduleReports.refreshTable,
      downloadReport: (state) => state.moduleReports.downloadReport,
    }),

    headers() {
      this.header = [
        { text: "Partner Code", value: "Partner Code" },
        { text: "Organization Name", value: "Organization Name" },
        { text: "Street", value: "Street" },
        { text: "City", value: "City" },
        { text: "State/Province/Region", value: "State/Province/Region" },
        { text: "Postal Code", value: "Postal Code" },
        { text: "Name", value: "Name" },
        { text: "Business Email Address", value: "Business Email Address" },
        { text: "Container Number", value: "Container Number" },
        { text: "Size", value: "Size" },
        { text: "Amount", value: "Amount" },
        { text: "Received $ date", value: "Received $ date" },
        { text: "Receipt Number", value: "Receipt Number" },
        { text: "Total amount of receipt", value: "Total amount of receipt" },
        { text: "Rebill number", value: "Rebill number" },
      ];
      this.setHeader(this.header);
      return generateHeaderData(this.header);
    },
    itemsFiltered() {
      let items = [];

      if (this.searchValue && this.searchValue.length) {
        items = search({
          searchInput: this.searchValue,
          header: this.header,
          arr: this.items,
        });
      } else {
        items = this.items;
      }
      return items;
    },
    formatedDates() {
      return this.dates
        ? this.dates.map((x) => parseFromIsoToFormat(x, "LL/dd/yyyy"))
        : "";
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      header: [],
      reportName: "Mass Rebill",
    };
  },
  watch: {
    refreshTable() {
      if (this.refreshTable) {
        this.getData();
        this.setRefreshTable(false);
      }
    },
    downloadReport() {
      if (this.downloadReport) {
        download_repots(this.itemsFiltered, this.header, this.reportName);
        this.setDownloadReport(false);
      }
    },
  },
  methods: {
    ...mapMutations({
      setHeader: "moduleReports/setHeader",
      setItems: "moduleReports/setItems",
      setRefreshTable: "moduleReports/setRefreshTable",
      setDownloadReport: "moduleReports/setDownloadReport",
    }),
    ...mapActions({
      getReportMassRebill: "moduleReports/getReportMassRebill",
    }),
    getData() {
      this.setItems([]);

      this.getReportMassRebill({
        SalRef: this.salRef,
        // dates: this.formatedDates,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
