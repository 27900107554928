<template>
  <div>
    <component
      :is="componentTable"
      :tab="tab"
      :parentTabValue="parentTabValue"
      :footerProps="footerProps"
    />
  </div>
  <!-- <div v-if="tab === 0">
    <SalesPendingExcel :tab="tab" :parentTabValue="parentTabValue" />
  </div>
  <div v-else-if="tab === 1">
    <MonthEndReport
      :tab="tab"
      :parentTabValue="parentTabValue"
      :footerProps="footerProps"
    />
  </div> -->
  <!-- <div v-else-if="tab === 5">
    <v-data-table
      class="elevation-5 report-table main-table table-readOnly rounded-0 pa-4 ma-0"
      dense
      fixed-header
      :headers="header"
      :items="data"
      height="70vh"
      width="100%"
      :no-data-text="errorMessage ? errorMessage : 'No data available'"
      :items-per-page="tab === 2 ? 1500 : 100"
      :footer-props="footerProps"
      :loading="loading"
      mobile-breakpoint="0"
      disable-pagination
      show-expand
      item-key="group"
    >
      <template v-slot:[`item.Order_Number`]="{ item }">
        <router-link :to="`orders/${item.Order_Number}`" target="_blank">
          {{ `${location}/orders/${item.Order_Number}` }}
        </router-link>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 20px !important; max-height: 500px"
          class="elevation-0"
        >
          <v-row class="px-2 pt-2">
            <v-col cols="auto">
              <span class="text-subtitle-1 font-weight-medium text-capitalize"
                >Container Level</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="releaseIssuePaymentHeader"
                dense
                :items="releaseIssuesContainer[item.Release_Ref]"
                hide-default-footer
                disable-pagination
                class="overflow-y-auto elevation-5 table-readOnly"
                fixed-header
                height="100%"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item.is_on_eoo`]="{ item }">
                  <span :style="{ color: item.is_on_eoo == 'Y' ? '#4ef956' : 'red' }">
                    {{ item.is_on_eoo }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </div>
  <div v-else>
    <v-data-table
      class="elevation-5 report-table main-table table-readOnly rounded-0 pa-4 ma-0"
      dense
      fixed-header
      :headers="header"
      :items="itemsFiltered"
      height="70vh"
      width="100%"
      :no-data-text="errorMessage ? errorMessage : 'No data available'"
      :items-per-page="tab === 2 ? 1500 : 100"
      :footer-props="footerProps"
      :loading="loading"
      mobile-breakpoint="0"
    >
    </v-data-table>
  </div> -->
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SalesPendingExcel from "./ExcelTable.vue";
import MonthEndReport from "@/components/Reports/MonthEndReport";
import MassRebill from "@/components/Reports/MassRebill";
import ReleaseIssue from "@/components/Reports/ReleaseIssue";

export default {
  props: ["tab", "parentTabValue"],
  components: {
    SalesPendingExcel,
    MonthEndReport,
    MassRebill,
    ReleaseIssue,
  },
  computed: {
    ...mapState({
      items: (state) => state.moduleReports.items,
      errorMessage: (state) => state.moduleReports.errorMessage,
      searchValue: (state) => state.moduleReports.searchValue,
      loading: (state) => state.moduleReports.loading,
    }),

    componentTable() {
      switch (this.tab) {
        case 0:
          return SalesPendingExcel;
        case 1:
          return MonthEndReport;
        case 2:
          return MassRebill;
        case 5:
          return ReleaseIssue;
      }
    },
    // itemsFiltered() {
    //   let getByData;
    //   if (this.filteredData && this.filteredData.length > 0) return this.filteredData;
    //   if (this.tab === 0 && this.tabFilter === 0) {
    //     getByData = this.items.filter(
    //       (item) => item.nereus_order_type && !item.nereus_order_type.includes("Gate")
    //     );
    //   }
    //   if (this.tab === 0 && this.tabFilter === 1) {
    //     getByData = this.items.filter(
    //       (item) => item.nereus_order_type && item.nereus_order_type.includes("Gate")
    //     );
    //   }
    //   if (this.searchValue && this.searchValue.length) {
    //     return search({
    //       searchInput: this.searchValue,
    //       header: this.header,
    //       arr: this.tab === 0 ? getByData : this.items,
    //     });
    //   } else {
    //     return this.tab === 0 ? getByData : this.items;
    //   }
    // },
  },
  // watch: {
  //   itemsFiltered: {
  //     handler() {
  //       this.setFilteredData(this.itemsFiltered);
  //       this.data = this.getData();
  //     },
  //     immediate: true,
  //   },
  // },
  data() {
    return {
      footerProps: { "items-per-page-options": [100, 150, 200] },
      filteredData: [],
      tabFilter: 0,
      columnsFiltered: null,
      data: [],
    };
  },

  methods: {
    ...mapMutations({
      setFilteredData: "moduleReports/setFilteredData",
    }),
    ...mapActions({
      getReportEndOfMonth: "moduleReports/getReportEndOfMonth",
    }),
  },
};
</script>

<style lang="scss"></style>
