<template>
  <v-data-table
    class="elevation-5 main-table2 rounded-1 pa-0 ma-0"
    dense
    fixed-header
    :headers="headers"
    :items="itemsFiltered"
    height="70vh"
    width="100%"
    :no-data-text="errorMessage ? errorMessage : 'No data available'"
    :items-per-page="100"
    :footer-props="footerProps"
    :loading="loading"
  >
  </v-data-table>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import { generateHeaderData } from "@/utils/tables.js";
import { search } from "@/utils/panelAction.js";
import { download_repots } from "@/components/Reports/utils";

export default {
  props: ["tab", "parentTabValue", "footerProps"],
  created() {
    this.getData();
  },
  computed: {
    ...mapState({
      items: (state) => state.moduleReports.items,
      dates: (state) => state.moduleReports.dates,
      errorMessage: (state) => state.moduleReports.errorMessage,
      searchValue: (state) => state.moduleReports.searchValue,
      loading: (state) => state.moduleReports.loading,
      salRef: (state) => state.moduleReports.salRef,
      monthSelected: (state) => state.moduleReports.monthSelected,
      refreshTable: (state) => state.moduleReports.refreshTable,
      downloadReport: (state) => state.moduleReports.downloadReport,
    }),

    headers() {
      this.header = [
        { text: "CONTAINERS", value: "CONTAINERS" },
        { text: "SIZE/TYPE", value: "SIZE/TYPE" },
        { text: "LEASE A. NUMBER", value: "LEASE A. NUMBER" },
        { text: "DEPOTS", value: "DEPOTS" },
        { text: "PRICES", value: "PRICES" },
        { text: "CURRENCY", value: "CURRENCY" },
        { text: "NOTES", value: "estimate_amount" },
      ];
      this.setHeader(this.header);
      return generateHeaderData(this.header);
    },
    itemsFiltered() {
      let items = [];

      if (this.searchValue && this.searchValue.length) {
        items = search({
          searchInput: this.searchValue,
          header: this.header,
          arr: this.items,
        });
      } else {
        items = this.items;
      }
      return items;
    },
    formatedDates() {
      return this.dates
        ? this.dates.map((x) => parseFromIsoToFormat(x, "LL/dd/yyyy"))
        : "";
    },
  },
  data() {
    return {
      header: [],
      reportName: "Month End Report",
    };
  },
  watch: {
    refreshTable() {
      if (this.refreshTable) {
        this.getData();
        this.setRefreshTable(false);
      }
    },
    downloadReport() {
      if (this.downloadReport) {
        download_repots(this.itemsFiltered, this.header, this.reportName);
        this.setDownloadReport(false);
      }
    },
  },
  methods: {
    ...mapMutations({
      setHeader: "moduleReports/setHeader",
      setItems: "moduleReports/setItems",
      setRefreshTable: "moduleReports/setRefreshTable",
      setDownloadReport: "moduleReports/setDownloadReport",
    }),
    ...mapActions({
      getReportEndOfMonth: "moduleReports/getReportEndOfMonth",
    }),
    getData() {
      this.setItems([]);

      this.getReportEndOfMonth({
        SalRef: this.salRef,
        // dates: this.formatedDates,
        nonGateBuy: this.parentTabValue === 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
