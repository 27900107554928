<template>
  <v-data-table
    class="elevation-5 main-table2 rounded-1 pa-0 ma-0"
    dense
    fixed-header
    :headers="headers"
    :items="itemsFiltered"
    height="70lvh"
    width="100%"
    :no-data-text="errorMessage ? errorMessage : 'No data available'"
    :items-per-page="10000"
    :footer-props="footerProps"
    :loading="loading"
  >
    <template v-for="column in headers" v-slot:[`header.${column.value}`]="{ header }">
      <FilterMenu
        v-if="itemsFiltered.length > 0"
        style="padding: 3px !important"
        :header="header"
        :column="column"
        :key="column.value"
        :items="items"
        :itemsFiltered="itemsFiltered"
        @filterData="filterData"
      />
      <span v-else :key="column.value"> {{ column.text }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import FilterMenu from "./FilterMenu.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { search, searchMultiFilter, searchByOperator } from "@/utils/panelAction.js";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import { download_repots } from "@/components/Reports/utils";
export default {
  props: ["tab", "parentTabValue"],
  components: {
    FilterMenu,
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState({
      items: (state) => state.moduleReports.items,
      dates: (state) => state.moduleReports.dates,
      errorMessage: (state) => state.moduleReports.errorMessage,
      searchValue: (state) => state.moduleReports.searchValue,
      loading: (state) => state.moduleReports.loading,
      refreshTable: (state) => state.moduleReports.refreshTable,
      downloadReport: (state) => state.moduleReports.downloadReport,
    }),
    headers() {
      this.header = [
        { text: "Container", value: "Container" },
        { text: "Size", value: "_Size" },
        { text: "Age", value: "Age" },
        { text: "Amount", value: "Amount" },
        { text: "Location", value: "Location" },
        { text: "Status", value: "Status" },
        { text: "Company Name", value: "Company_Name" },
        { text: "Supplier Number", value: "Supplier_Number" },
        { text: "Contact Name", value: "Contact_Name" },
        { text: "Date of Quote", value: "Date_of_Quote" },
        { text: "Date Sold", value: "Date_Sold" },
        { text: "Payment Recvd", value: "Payment_Recvd" },
        { text: "Release sent DATE", value: "Release_sent" },
        { text: "Release Number", value: "Release_Number" },
        { text: "Monthly Contract #", value: "Monthly_Contract_#" },
        { text: "Rebill sent", value: "Rebill_Sent" },
        { text: "Rebill", value: "Rebill" },
        { text: "Outgate Date", value: "Outgate_Date" },
        { text: "MSU", value: "MSU" },
        { text: "Notes", value: "Notes" },
        { text: "PAYMENT RECEIVED", value: "PAYMENT_RECEIVED" },
        { text: "Transaction#", value: "Transaction_hash" },
        { text: "Date", value: "Date" },
        { text: "PICK UP EXPIRATION", value: "PICK_UP_EXPIRATION" },
        { text: "Nereus Order ID", value: "nereus_order_id" },
        { text: "Week", value: "week" },
        { text: "Status Code", value: "InboundStatusCode" },
      ];
      this.setHeader(this.header);
      return generateHeaderData(this.header);
    },
    itemsFiltered() {
      let items = [];

      if (this.searchValue && this.searchValue.length) {
        items = search({
          searchInput: this.searchValue,
          header: this.header,
          arr: this.items,
        });
      } else {
        items = this.items;
      }

      if (this.filterValue) {
        const { key, options, operator, searchValue } = this.filterValue;

        if (options.length !== 0) {
          items = searchMultiFilter({
            searchInputs: options,
            key,
            items: items,
          });
        } else if (searchValue !== "" && operator !== "") {
          items = searchByOperator({
            operator,
            searchValue,
            key,
            items: items,
          });
        }
      }
      return items;
    },
    formatedDates() {
      return this.dates
        ? this.dates.map((x) => parseFromIsoToFormat(x, "LL/dd/yyyy"))
        : "";
    },
  },
  watch: {
    refreshTable() {
      if (this.refreshTable) {
        this.getData();
        this.setRefreshTable(false);
      }
    },
    downloadReport() {
      if (this.downloadReport) {
        download_repots(this.itemsFiltered, this.header, this.reportName);
        this.setDownloadReport(false);
      }
    },
  },
  data() {
    return {
      footerProps: { "items-per-page-options": [10000, 150, 200, 20000] },
      filterValue: null,
      header: [],
      reportName: "Report_Sale_Pending",
    };
  },

  methods: {
    ...mapMutations({
      setHeader: "moduleReports/setHeader",
      setRefreshTable: "moduleReports/setRefreshTable",
      setDownloadReport: "moduleReports/setDownloadReport",
    }),
    ...mapActions({
      getReportSalePending: "moduleReports/getReportSalePending",
      getReportSalePendingGB: "moduleReports/getReportSalePendingGB",
    }),
    filterData(value) {
      this.filterValue = value;
    },
    getData() {
      this.filterValue = null;
      if (this.parentTabValue === 0) {
        this.getReportSalePending({
          // dates: this.formatedDates,
          scope: "order",
        });
      } else {
        this.getReportSalePendingGB({
          // dates: this.formatedDates,
          scope: "order",
        });
      }
    },
  },
};
</script>

<style></style>
