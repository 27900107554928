<template>
  <v-row class="pa-0 elevation-5">
    <v-col class="col-ajust" v-for="{ title, disable, index } in tabValues" :key="title">
      <v-btn
        v-if="!disable"
        small
        class="px-5 py-2 ma-0 primary--text rounded-0 text-lg-caption font-weight-bold"
        :class="index === tab ? 'primary dark_blue--text bold-text' : ''"
        dense
        block
        style="border-left: thin solid #3284f4; border-right: thin solid #3284f4"
        :disabled="loading"
        @click="$emit('changeTab', index)"
      >
        <span class="text-capitalize">
          {{ title }}
        </span>
      </v-btn>

      <v-btn
        v-if="disable"
        small
        class="px-5 py-2 ma-0 primary--text rounded-0 text-lg-caption font-weight-bold"
        :class="index === tab ? 'primary dark_blue--text bold-text' : ''"
        dense
        block
        style="border-left: thin solid #3284f4; border-right: thin solid #3284f4"
        :disabled="true"
        @click="$emit('changeTab', index)"
      >
        <span class="text-capitalize">
          {{ title }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["tab", "loading", "parentTab"],
  data() {
    return {};
  },
  computed: {
    tabValues() {
      return this.parentTab === 0
        ? [
            {
              title: "Sale Pending",
              index: 0,
            },

            {
              title: "Month End Report",
              index: 1,
            },
            {
              title: "Mass Rebill",
              index: 2,
            },
            {
              title: "Download BI Weekly Report",
              index: 4,
            },
            {
              title: "Release Issues",
              index: 5,
            },
          ]
        : [
            {
              title: "Sale Pending",
              index: 0,
            },
            {
              title: "Month End Report",
              index: 1,
            },
          ];
    },
  },
};
</script>

<style></style>
