<template>
  <v-card class="fill-height d-flex flex-column">
    <v-tabs v-model="tab" dark class="tabs">
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="mb-auto mt-auto">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <v-card-text>
            <component v-bind:is="item.content"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Notifications from "./Notifications";
import Security from "./Security";
import PersonalDetails from "./PersonalDetails";
import Config from "./Config";
import SupportDocs from "@/components/Settings/SupportDocs.vue";

export default {
  components: {
    Notifications,
    Security,
    PersonalDetails,
    Config,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: "Personal Details", content: PersonalDetails },
        { tab: "Notifications", content: Notifications },
        { tab: "Security", content: Security },
        { tab: "Config", content: Config },
        { tab: "Support Docs", content: SupportDocs },
      ],
    };
  },
};
</script>

<style scoped>
.tabs {
  flex: none !important;
}
</style>
