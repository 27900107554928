<template>
  <div class="d-flex flex-column align-center">
    <v-card  elevation="3">
      <v-card-title>
        You can manage your notifications here by turning them on or off.
      </v-card-title>
      <v-card-text>
        <v-switch
          inset
          class="ma-5"
          v-model="email"
          label="Notifications via email"
        ></v-switch>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: false,
    };
  },
};
</script>

<style></style>
