<template>
  <v-form v-model="valid" class="d-flex flex-column align-center">
    <p class="title">Update your personal information</p>
    <v-row class="row row-w">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="firstname"
          :rules="nameRules"
          label="Full name"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="position"
          :rules="nameRules"
          label="Position"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="row-w">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="phone" label="Phone" required></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-btn block color="primary" elevation="2">Save</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    fullName: "",
    position: "",
    nameRules: [
      v => !!v || "Name is required",
      v => v.length <= 25 || "Name must be less than 25 characters",
    ],
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    phone: "",
  }),
};
</script>
<style scoped>
.title {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #c1c2cf;
  width: 100%;
  text-align: center;
}
.row-w {
  width: 100%;
}
</style>
