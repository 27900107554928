<template>
  <iframe
    style="height: 100vh"
    width="100%"
    height="100%"
    src="https://help.nereus.io/?page_id=30"
  >
  </iframe>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    this.userInfo.name = this.user.name;
    this.userInfo.email = this.user.email;
    this.userInfo.org = this.user.organization_descr;
  },
  data: () => ({
    valid: false,
    userInfo: {
      name: "",
      org: "",
      email: "",
      phone: "",
    },
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 25 || "Name must be less than 25 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style>
.v-input__prepend-outer {
  margin: 0;
  padding: 0;
}
.userInfo-input.theme--dark.v-input--is-disabled input {
  color: white;
}
.v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer,
.v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer {
  margin: 0;
}
</style>
<style scoped>
.row-w {
  width: 100%;
}

.icon-form {
  border: 1px solid #3284f4;
  border-radius: 50%;
  padding: 15%;
  margin-right: 20px;
  margin-top: 0;
}
</style>
