<template>
  <div class="d-flex flex-column align-center">
    <v-card elevation="3" width="70%">
      <v-card-title>
        <div>Prevent <strong>release</strong> from being created</div>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="release_option" mandatory>
          <v-radio value="ANYTIME">
            <template v-slot:label>
              <div>Create a release at any time in order workflow</div>
            </template>
          </v-radio>
          <v-radio value="ORDER_FULLY_PAID">
            <template v-slot:label>
              <span>
                Release may only be created if the Balance Due amount on an
                order = $0.00 (entire order must be paid for)
              </span>
            </template>
          </v-radio>
        </v-radio-group>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-1 pa-0">
            <v-btn
              class="elevation-5"
              color="success"
              text
              @click="saveReleaseOption"
              block
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      organizationConfig: null,
      release_option: "",
    };
  },
  computed: {
    ...mapState({ orgConfig: (state) => state.moduleOrganizations.orgConfig }),
  },
  methods: {
    ...mapActions({
      getOrganizationConfig: "moduleOrganizations/getOrganizationConfig",
      createOrganizationConfig: "moduleOrganizations/createOrganizationConfig",
      updateOrganizationConfig: "moduleOrganizations/updateOrganizationConfig",
    }),
    async loadData() {
      await this.getOrganizationConfig();

      this.release_option = this.orgConfig.release_option
        ? this.orgConfig.release_option
        : "ANYTIME";
    },
    async saveReleaseOption() {
      if (this.orgConfig.id !== undefined) {
        await this.updateOrganizationConfig({
          release_option: this.release_option,
          id: this.orgConfig.id,
        });
      } else {
        await this.createOrganizationConfig({
          release_option: this.release_option,
        });
      }
    },
  },
  async created() {
    this.loadData();
  },
};
</script>

<style></style>
