<template>
  <v-col class="elevation-5 align-self-end px-2" cols="12">
    <v-row justify="end" align="end">
      <v-col class="px-2 py-0 ma-0">
        <v-text-field
          class="ma-0 rounded-lg"
          hide-details
          type="text"
          outlined
          dense
          clearable
          :prepend-inner-icon="'mdi-magnify'"
          single-line
          :value="searchValue"
          @input="(value) => setSearchValue(value)"
        >
          <template v-slot:label><span class="pa-0 ma-0"> Search </span> </template>
        </v-text-field>
      </v-col>

      <v-col class="pa-1 col-ajust" v-if="tab !== 0 && tab !== 5">
        <v-autocomplete
          :value="monthSelected"
          :items="salMonths"
          color="white"
          dense
          solo
          background-color="#20212E"
          hide-details
          class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center"
          item-text="text"
          item-value="value"
          @input="(value) => setMonthSelected(value)"
        >
        </v-autocomplete>
      </v-col>
      <v-col class="pa-1 col-ajust" v-if="tab !== 0 && tab !== 5">
        <v-text-field
          background-color="#20212E"
          class="ma-0 rounded-0 text-field__calendar"
          dense
          solo
          hide-details
          :value="salRef"
          @input="(value) => setSalRef(value)"
          placeholder="Sal Ref"
          clearable
          @keyup.enter="generateDateRange"
        >
        </v-text-field>
      </v-col>

      <!-- <v-col class="pa-1" cols="2" v-if="tab !== 5">
        <v-menu
          v-model="showDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              background-color="#20212E"
              class="ma-0 rounded-0 text-field__calendar"
              dense
              solo
              hide-details
              prepend-inner-icon="mdi-calendar"
              :value="datesText"
              v-bind="attrs"
              v-on="on"
              @click:prepend-inner="showDatePicker = true"
              readonly
              placeholder="Range of Dates"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            :value="dates"
            @input="(value) => setDates(value)"
            no-title
            class="date-picker"
            color="primary"
            range
          ></v-date-picker>
        </v-menu>
      </v-col> -->
      <v-col class="col-ajust pa-1" v-if="tab === 2">
        <v-btn
          small
          class="button elevation-5"
          color="primary"
          dense
          block
          @click="handleFileImport"
        >
          <span class="text-capitalize"> Upload Report </span>
        </v-btn>
        <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
      </v-col>
      <v-col class="col-ajust pa-1">
        <v-btn
          small
          class="button elevation-5"
          color="green"
          dense
          block
          @click="getData"
        >
          <span class="text-capitalize"> Generate Report </span>
        </v-btn>
      </v-col>
      <v-col class="col-ajust pa-1">
        <v-btn
          small
          class="button elevation-5"
          color="primary"
          dense
          block
          @click="downloadReport"
        >
          <span class="text-capitalize"> Export </span>
        </v-btn>
      </v-col>
      <v-col class="col-ajust pa-1" v-if="tab !== 5">
        <v-btn
          small
          class="button elevation-5"
          color="primary"
          dense
          block
          @click="getData"
        >
          <span class="text-capitalize"> Reset </span>
        </v-btn>
      </v-col>
    </v-row>
    <ModalImportMassRebill
      v-if="showModalImportMassRebill"
      :show="showModalImportMassRebill"
      title="imported massrebill file"
      :items="dataModalImportMassRebill"
      @closeModal="showModalImportMassRebill = false"
    />
  </v-col>
</template>

<script>
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import { mapActions, mapMutations, mapState } from "vuex";

import ModalImportMassRebill from "@/components/Reports/ModalImportMassRebill.vue";

export default {
  props: ["tab", "parentTab"],
  components: {
    ModalImportMassRebill,
  },
  data() {
    return {
      showDatePicker: false,
      salMonths: [
        { text: "January", value: 0 },
        { text: "February", value: 1 },
        { text: "March", value: 2 },
        { text: "April", value: 3 },
        { text: "May", value: 4 },
        { text: "June", value: 5 },
        { text: "July", value: 6 },
        { text: "August", value: 7 },
        { text: "September", value: 8 },
        { text: "October", value: 9 },
        { text: "November", value: 10 },
        { text: "December", value: 11 },
      ],

      showModalImportMassRebill: false,
      dataModalImportMassRebill: {},
    };
  },
  computed: {
    ...mapState({
      fileURL: (state) => state.moduleReports.fileURL,
      fileName: (state) => state.moduleReports.fileName,
      items: (state) => state.moduleReports.items,
      header: (state) => state.moduleReports.header,
      errorMessage: (state) => state.moduleReports.errorMessage,
      searchValue: (state) => state.moduleReports.searchValue,
      filteredData: (state) => state.moduleReports.filteredData,
      releaseIssuesContainer: (state) => state.moduleReports.releaseIssuesContainer,
      dates: (state) => state.moduleReports.dates,
      salRef: (state) => state.moduleReports.salRef,
      monthSelected: (state) => state.moduleReports.monthSelected,
    }),
    datesText() {
      return this.dates.map((x) => parseFromIsoToFormat(x, "LL/dd/yyyy")).join(" ~ ");
    },
  },
  watch: {
    monthSelected() {
      this.generateSalRef();
      this.generateDateRange();
    },
    tab: {
      async handler() {
        this.generateSalRef();
      },
      immediate: true,
    },
    parentTab: {
      async handler() {
        this.setItems([]);
        this.setFileName("");
        this.getData();
      },
    },
  },
  methods: {
    ...mapMutations({
      setItems: "moduleReports/setItems",
      setFileName: "moduleReports/setFileName",
      setSearchValue: "moduleReports/setSearchValue",
      setMonthSelected: "moduleReports/setMonthSelected",
      setSalRef: "moduleReports/setSalRef",
      setDates: "moduleReports/setDates",
      setRefreshTable: "moduleReports/setRefreshTable",
      setDownloadReport: "moduleReports/setDownloadReport",
    }),
    ...mapActions({
      uploadMassRebill: "moduleReports/uploadMassRebill",
      generateSalRef: "moduleReports/generateSalRef",
      generateDates: "moduleReports/generateDates",
      generateDateRange: "moduleReports/generateDateRange",
    }),
    async onFileChanged(e) {
      var files = e.target.files || e.dataTransfer.files;
      let file = e.target.files[0];

      if (!files.length) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      const extension = file.name.split(".").pop();

      if (
        extension === "php" ||
        extension === "exe" ||
        extension === "bat" ||
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png" ||
        extension === "pdf" ||
        extension === "gif"
      ) {
        alert("invalid format");
        return;
      }

      let res = await this.uploadMassRebill(formData);
      this.$refs.uploader.value = "";
      this.showModalImportMassRebill = true;
      this.dataModalImportMassRebill = res.data;
    },
    handleFileImport() {
      this.$refs.uploader.click();
    },
    async getData() {
      this.setRefreshTable(true);
    },
    async downloadReport() {
      this.setDownloadReport(true);
    },

    async resetData() {
      await getData();
    },
  },
};
</script>

<style></style>
