<template>
  <div class="d-flex flex-column align-center">
    <v-card elevation="3" width="70%">
      <v-card-text>
        <v-row align="center" justify="center" class="py-2">
          <v-col cols="auto">
            <span class="text-subtitle-1 white--text font-weight-bold"
              >CC for winning lots:
            </span>
          </v-col>
          <v-col cols="6">
            <v-text-field
              solo
              dense
              full-width
              placeholder="example@something.com"
              hide-details
              v-model="ccWin"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="elevation-5 mx-5"
              color="success"
              text
              block
              @click="saveAuctionCC"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" class="py-2">
          <v-col cols="auto">
            <span class="text-subtitle-1 white--text font-weight-bold"
              >Upload Master Contract
            </span>
          </v-col>

          <v-col cols="2" class="px-2">
            <v-btn
              color="#20212E"
              class="elevation-5 pa-2 px-5 button-outlined"
              block
              @click="showModalUploadModal = true"
            >
              <span>Upload Master Contract</span>
            </v-btn>
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <DocumentsModal
      :show="showModalUploadModal"
      :width="100"
      title="Upload Master Contract"
      :masterContract="true"
      @showModal="showModalUploadModal = false"
      @uploadMasterContract="uploadMasterContract"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DocumentsModal from "@/components/Organizations/UploadDocumentsModal.vue";

export default {
  components: {
    DocumentsModal,
  },
  async created() {
    const res = await this.getCarbonCopy();
    this.ccWin = res.email;
  },
  data() {
    return {
      ccWin: "",
      showModalUploadModal: false,
    };
  },
  methods: {
    ...mapActions({
      saveCarbonCopyEmail: "moduleAuctions/saveCarbonCopyEmail",
      getCarbonCopy: "moduleAuctions/getCarbonCopy",
      uploadMasterContractStore: "uploadMasterContract",
    }),

    saveAuctionCC() {
      this.saveCarbonCopyEmail(this.ccWin);
    },
    async uploadMasterContract({ file }) {
      let formData = new FormData();

      formData.append("file", file);

      let response = await this.uploadMasterContractStore(formData);

      if (response) {
        this.showModalUploadModal = false;
      }
    },
  },
};
</script>

<style></style>
