<template>
  <v-data-table
    class="elevation-5 report-table main-table table-readOnly rounded-0 pa-4 ma-0"
    dense
    fixed-header
    :headers="headerMain"
    :items="itemsFiltered"
    height="70vh"
    width="100%"
    :no-data-text="errorMessage ? errorMessage : 'No data available'"
    :items-per-page="100"
    :footer-props="footerProps"
    :loading="loading"
    mobile-breakpoint="0"
    disable-pagination
    show-expand
    item-key="group"
  >
    <template v-slot:[`item.Order_Number`]="{ item }">
      <router-link :to="`orders/${item.Order_Number}`" target="_blank">
        {{ `${location}/orders/${item.Order_Number}` }}
      </router-link>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td
        :colspan="headers.length"
        style="padding: 20px !important; max-height: 500px"
        class="elevation-0"
      >
        <v-row class="px-2 pt-2">
          <v-col cols="auto">
            <span class="text-subtitle-1 font-weight-medium text-capitalize"
              >Container Level</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="releaseIssuePaymentHeader"
              dense
              :items="releaseIssuesContainer[item.Release_Ref]"
              hide-default-footer
              disable-pagination
              class="overflow-y-auto elevation-5 table-readOnly"
              fixed-header
              height="100%"
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.is_on_eoo`]="{ item }">
                <span :style="{ color: item.is_on_eoo == 'Y' ? '#4ef956' : 'red' }">
                  {{ item.is_on_eoo }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
import { search } from "@/utils/panelAction.js";
import { download_repots } from "@/components/Reports/utils";

export default {
  props: ["tab", "parentTabValue", "footerProps"],
  created() {
    this.getData();
  },
  computed: {
    ...mapState({
      items: (state) => state.moduleReports.items,
      errorMessage: (state) => state.moduleReports.errorMessage,
      searchValue: (state) => state.moduleReports.searchValue,
      loading: (state) => state.moduleReports.loading,
      refreshTable: (state) => state.moduleReports.refreshTable,
      releaseIssuesContainer: (state) => state.moduleReports.releaseIssuesContainer,
      downloadReport: (state) => state.moduleReports.downloadReport,
    }),
    releaseIssuePaymentHeader() {
      return generateHeaderData([
        { text: "Container No.", value: "ContainerNo" },
        { text: "Size.", value: "Size" },
        { text: "Type", value: "Type" },
        { text: "Price", value: "price" },
        { text: "Transaction #", value: "transaction" },
        { text: "Is on EOO", value: "is_on_eoo" },
      ]);
    },
    releaseIssueContainersHeader() {
      return generateHeaderData([{ text: "Containers", value: "EquipmentID" }]);
    },
    location() {
      return window.location.origin;
    },
    headerMain() {
      this.header = [
        { text: "Release Ref", value: "Release_Ref" },
        { text: "Inventory Count", value: "Inventory_Count" },
        { text: "Count on Order", value: "Count_On_Order" },
        { text: "Order Number", value: "Order_Number" },
      ];
      this.setHeader(this.header);
      return generateHeaderData(this.header);
    },
    itemsFiltered() {
      let items = [];

      if (this.searchValue && this.searchValue.length) {
        items = search({
          searchInput: this.searchValue,
          header: this.header,
          arr: this.items,
        });
      } else {
        items = this.items;
      }

      items = items.reduce((acc, item) => {
        const key = `${item.Release_Ref}_${item.Order_Number}`;
        acc[key] = acc[key] ? [...acc[key], item] : [item];
        return acc;
      }, {});

      return Object.values(items).map((x) => {
        return { ...x[0], group: `${x[0].Release_Ref}_${x[0].Order_Number}` };
      });
    },
  },
  data() {
    return {
      header: [],
      reportName: "Release Issues",
    };
  },
  watch: {
    refreshTable() {
      if (this.refreshTable) {
        this.getData();
        this.setRefreshTable(false);
      }
    },
    downloadReport() {
      if (this.downloadReport) {
        const data = [];

        Object.values(this.releaseIssuesContainer).forEach((x) => {
          let orderData = this.itemsFiltered.filter((value) => {
            return value.Release_Ref === x[0].Release_Ref;
          });
          x.forEach((value) => {
            value.Inventory_Count = orderData[0].Inventory_Count;
            value.Count_On_Order = orderData[0].Count_On_Order;
            value.Order_Number = orderData[0].Order_Number;
          });

          data.push(...x);
        });

        let header = [
          { text: "Release Ref", value: "Release_Ref" },
          { text: "Inventory Count", value: "Inventory_Count" },
          { text: "Count on Order", value: "Count_On_Order" },
          { text: "Order Number", value: "Order_Number" },
          { text: "Container No.", value: "ContainerNo" },
          { text: "Size.", value: "Size" },
          { text: "Type", value: "Type" },
          { text: "Price", value: "price" },
          { text: "Transaction #", value: "transaction" },
          { text: "Is on EOO", value: "is_on_eoo" },
        ];

        download_repots(data, header, this.reportName);
        this.setDownloadReport(false);
      }
    },
  },
  methods: {
    ...mapMutations({
      setHeader: "moduleReports/setHeader",
      setItems: "moduleReports/setItems",
      setRefreshTable: "moduleReports/setRefreshTable",
      setDownloadReport: "moduleReports/setDownloadReport",
    }),
    ...mapActions({
      reportReleaseIssues: "moduleReports/reportReleaseIssues",
    }),
    getData() {
      this.setItems([]);

      this.reportReleaseIssues();
    },
  },
};
</script>

<style lang="scss" scoped>
.report-table {
  .v-data-table__wrapper {
    table tbody tr td {
      padding: 0 16px !important;
    }
  }
}
</style>
