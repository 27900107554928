<template>
  <v-row>
    <v-col cols="12">
      <v-card class="mx-auto">
        <v-tabs v-model="tab" dark centered>
          <v-tab v-for="item in items2" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mb-auto mt-auto">
          <v-tab-item v-for="item in items2" :key="item.tab">
            <v-card flat>
              <v-card-text>
                <component v-bind:is="item.content"></component>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="mx-auto">
        <v-tabs v-model="tab" dark centered>
          <v-tab v-for="item in items" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mb-auto mt-auto">
          <v-tab-item v-for="item in items" :key="item.tab">
            <v-card flat>
              <v-card-text>
                <component v-bind:is="item.content"></component>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Releases from "./Releases";
import ConfigAuctions from "./ConfigAuctions.vue";
export default {
  components: {
    Releases,
    ConfigAuctions,
  },
  data() {
    return {
      tab: null,
      items: [{ tab: "Releases", content: Releases }],
      items2: [{ tab: "Auctions", content: ConfigAuctions }],
    };
  },
};
</script>

<style scoped>
.tabs {
  flex: none !important;
}
</style>
