<template>
  <v-card class="fill-height d-flex flex-column ma-auto align-center" max-width="400" tile>
    <v-list-item two-line>
      <v-list-item-content>
        <div class="overline mb-4">SuperContainers, LLC</div>
        <v-list-item-title class="headline mb-1">
          Hi, Tom Hanks!
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar tile size="150" color="grey">
        <img
          src="https://i.guim.co.uk/img/media/cdbd141377fd232bb79398a236b279ae38aa955a/0_270_4134_2480/master/4134.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=608c7f19eb9e43c59d368eed152d810e"
          alt="Tom Hanks"
      /></v-list-item-avatar>
    </v-list-item>

    <!-- <v-divider inset class="divider--white"></v-divider> -->

    <v-card-subtitle class="pb-0 subtitle-1"> Profile </v-card-subtitle>

    <v-card-text class="text--primary px-7 pt-1">
      <div class="user-data">
        <p>Representant of <em>SuperContainers, LLC</em></p>
        <v-divider class="divider--white"></v-divider>
      </div>
      <div class="user-data">
        <p>Los Angeles, California</p>
        <v-divider class="divider--white"></v-divider>
      </div>
      <div class="user-data">
        <p>+1 521 555 5525</p>
        <v-divider class="divider--white"></v-divider>
      </div>
      <div class="user-data">
        <p>tom@nereus.io</p>
      </div>
    </v-card-text>

    <!-- <v-card-actions>
      <v-btn color="orange" text> Share </v-btn>

      <v-btn color="orange" text> Explore </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
export default {
  name: "UserCard",
};
</script>

<style scoped>
.theme--dark.v-divider {
  border-color: #0084fc !important;
}
</style>