<template>
  <v-form v-model="valid" class="d-flex flex-column align-center pa-5">
    <p class="title">Need to reset your password?</p>
    <v-row class="row row-w ma-0" justify="center">
      <v-col cols="12" md="6" class="ma-0 pa-0">
        <v-text-field
          v-model="oldPassword"
          placeholder=" "
          autocomplete="new-password"
          label="Current Password"
          required
          solo
          dense
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          hint="At least 8 characters"
          @click:append="show1 = !show1"
        >
          <template v-slot:prepend>
            <v-icon class="icon-form" color="primary">mdi-pencil</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show2 ? 'text' : 'password'"
          name="input-10-1"
          hint="At least 8 characters"
          counter
          @click:append="show2 = !show2"
          placeholder=" "
          autocomplete="new-password"
          v-model="newPassword"
          label="New Password"
          required
          solo
          dense
        >
          <template v-slot:prepend>
            <v-icon class="icon-form" color="primary">mdi-key</v-icon>
          </template>
        </v-text-field>
        <v-text-field
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show3 ? 'text' : 'password'"
          name="input-10-1"
          hint="At least 8 characters"
          counter
          @click:append="show3 = !show3"
          placeholder=" "
          autocomplete="new-password"
          v-model="passwordCheck"
          label="Confirm Password"
          required
          solo
          dense
        >
          <template v-slot:prepend>
            <v-icon class="icon-form" color="primary">mdi-key-change</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="row row-w ma-0">
      <v-col cols="2" md="2" class="ma-auto pa-0 d-flex">
        <v-btn @click="saveNewPassword" block color="primary" elevation="2"
          >Save new password</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { change_password_private } from "../../helpers/api/indexv2";

export default {
  data: () => ({
    valid: false,
    oldPassword: "",
    newPassword: "",
    passwordCheck: "",
    show1: false,
    show2: true,
    show3: false,
    show4: false,
    password: "Password",
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
  }),

  mounted() {
    this.setSpinnerShow(false);
  },

  methods: {
    ...mapMutations(["setSnackbar", "setSpinnerShow"]),
    async saveNewPassword() {
      this.setSpinnerShow(true);

      try {
        const response = await change_password_private({
          oldpassword: this.oldPassword,
          newpassword: this.newPassword,
        });

        this.setSpinnerShow(false);

        this.setSnackbar({
          text: "Password updated succesfully",
          status: "success",
          show: "showLog",
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.passwordCheck = "";
      } catch (error) {
        this.setSpinnerShow(false);

        this.setSnackbar({
          text: error.message,
          status: "danger",
          show: "showLog",
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.passwordCheck = "";
      }
      this.setSpinnerShow(false);
    },
  },
};
</script>

<style>
.v-input__prepend-outer {
  margin: 0;
  padding: 0;
}

.v-text-field.v-text-field--solo.v-input--dense .v-input__prepend-outer,
.v-text-field.v-text-field--solo.v-input--dense .v-input__append-outer {
  margin: 0;
}
</style>
<style scoped>
.row-w {
  width: 100%;
}

.icon-form {
  border: 1px solid #3284f4;
  border-radius: 50%;
  padding: 15%;
  margin-right: 20px;
  margin-top: 0;
}
</style>
