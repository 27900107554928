<template>
  <v-card class="fill-height d-flex flex-column elevation-5">
    <v-tabs v-model="tab" class="tabs" background-color="dark_blue" centered>
      <v-tab v-for="item in items" :key="item.tab" class="tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="mb-auto mt-auto dark-blue">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat class="tab-item">
          <!-- <v-card-text> -->
          <component v-bind:is="item.content"></component>
          <!-- </v-card-text> -->
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Notifications from "./Notifications";
import Security from "./Security";
import UserInformation from "@/components/Settings/UserInformation.vue";
import SupportDocs from "@/components/Settings/SupportDocs.vue";
import Config from "./Config";

export default {
  components: {
    Notifications,
    Security,
    UserInformation,
    Config,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: "Personal Details", content: UserInformation },
        { tab: "Notifications", content: Notifications },
        { tab: "Security", content: Security },
        { tab: "Config", content: Config },
        { tab: "Support Docs", content: SupportDocs },
      ],
    };
  },
};
</script>

<style scoped>
.tabs {
  flex: none !important;
}

.tab {
  font-size: 0.8em;
}

/* 
.tab-item{
  background-color: #303030;
} */
</style>
